const evaluate = (statement) => {
	try {
		// eslint-disable-next-line
		eval(statement);
	} catch (e) {
		return false;
	}

	return true;
};

// eslint-disable-next-line no-extra-parens
const isModernBrowser = (
	'Promise' in window &&
	'fetch' in window &&
	'assign' in Object &&
	'from' in Array &&
	evaluate('const {a} = {a:1}') // To test for ES8/2017 support: evaluate('async ()=>{}')
);

const source = isModernBrowser ? '/js/main.min.js' : '/js/main.es5.min.js';
const script = document.createElement('script');

script.src = source;

document.body.appendChild(script);
